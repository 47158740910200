import "./index.css";
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsCategorySelected, setIsTypeSelected } from '../../Routes/Slices/dashBoardSlice';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Offcanvas } from "react-bootstrap";
import { useState } from 'react';

function NavComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    dispatch(setIsCategorySelected(false));
    dispatch(setIsTypeSelected(false));
    navigate("/");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar className="navContainer bg-primary fixed-top" expand="lg" id="navContainer">
        <Container>
          <Navbar.Brand href="#">
            <img
              src="AI-Writer (1).png"
              alt="Brand Logo"
              className="d-inline-block align-top"
              style={{ width: '150px', height: 'auto' }} // Adjust the width and height as needed
            />
          </Navbar.Brand>

          <Button variant="primary" className="d-lg-none" onClick={handleShow}>

            <i className="fas fa-bars"></i>
          </Button>
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block">
            <Nav className="ms-auto">
              <NavLink to="/dashboard" className="nav-link linkInNav custom-nav-link">
                <i className="fas fa-home me-1"></i> Home
              </NavLink>
              <NavLink to="/user/setting" className="nav-link linkInNav custom-nav-link">
                <i className="fas fa-cog me-1"></i> Settings
              </NavLink>
              <NavLink to="/template" className="nav-link linkInNav custom-nav-link">
                <i className="fas fa-file-alt me-1"></i> Templates
              </NavLink>
              <span className="nav-link linkInNav custom-nav-link">
                <i className="fas fa-user me-1"></i> {user}
              </span>
              <NavLink to="/" className="nav-link linkInNav custom-nav-link" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt me-1"></i> Logout
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <NavLink to="/dashboard" className="nav-link linkInNav custom-nav-link" onClick={handleClose}>
              <i className="fas fa-home me-1"></i> Home
            </NavLink>
            <NavLink to="/user/setting" className="nav-link linkInNav custom-nav-link" onClick={handleClose}>
              <i className="fas fa-cog me-1"></i> Settings
            </NavLink>
            <NavLink to="/template" className="nav-link linkInNav custom-nav-link" onClick={handleClose}>
              <i className="fas fa-file-alt me-1"></i> Templates
            </NavLink>
            {/* <span className="nav-link linkInNav custom-nav-link">
              <i className="fas fa-user me-1"></i> {user}
            </span> */}
            <NavLink to="/" className="nav-link linkInNav custom-nav-link" onClick={() => { handleLogout(); handleClose(); }}>
              <i className="fas fa-sign-out-alt me-1"></i> Logout
            </NavLink>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default NavComponent;
